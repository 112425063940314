<template>
  <router-view/>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@400;600&display=swap');

body {
  background-color: #1a1a1a;
  color: #fff;
  font-family: 'Fredoka', sans-serif;
}
</style>
