<template>
  <div id="home-wrapper">
    <Room v-for="room in rooms.filter((room) => { return room != null })" :room="room"
          :key=room.room></Room>
  </div>
</template>

<script>
import Room from '@/components/Room';

export default {
  name: 'HomeView',
  data() {
    return {
      rooms: []
    }
  },
  components: {Room},
  async beforeMount() {
    const url = 'https://api.vaede.com/'
    const response = await fetch(url)
    if (response.ok) {
      const json = await response.json()
      this.rooms = json.rooms
    } else {
      alert('API IS BROKEN')
    }
  }
}
</script>

<style scoped>
#home-wrapper {
  display: flex;
  margin: 15% 5%;
  text-align: center;
  justify-content: space-between;
}

</style>
