<template>
  <div class="room">
    <h2 class="room-title">{{ room.room }}</h2>
    <div class="room-warning-icon-wrapper">
      <div class="room-warning-icon icon-cold" v-if="room.temperature < 18.5">
        <i class="fa-solid fa-snowflake"></i>
      </div>
      <div class="room-warning-icon icon-humidity" v-if="room.humidity > 55">
        <i class="fa-solid fa-droplet"></i>
      </div>
    </div>
    <div class="room-numbers">
      <div class="room-temperature">{{ roundNumber(room.temperature) }}°C</div>
      <div class="room-humidity">{{ roundNumber(room.humidity) }}%</div>
    </div>
    <div class="room-last-update">
      <span v-if="lastUpdate(room.measure_date_time) > 0">
        Il y a {{ lastUpdate(room.measure_date_time) }} min<span v-if="lastUpdate(room.measure_date_time) > 1">s</span>
      </span>
      <span v-else>
        Maintenant
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Room-component',
  props: {
    room: Object
  },
  data() {
    return {
      rooms: []
    }
  },
  methods: {
    roundNumber(number) {
      return number.toFixed(2);
    },
    lastUpdate(measureDateTime) {
      const lastUpdate = new Date(measureDateTime)
      const delta = new Date() - lastUpdate
      return Math.round(((delta % 86400000) % 3600000) / 60000);
    }
  }
}
</script>

<style scoped>
.room {
  border: 1px solid;
  border-radius: 10px;
  width: 30%;
  margin: 20px;
}

.room-title {
  position: relative;
  top: -52px;
  background-color: #1a1a1a;
  width: 200px;
  left: 20px;
  border: 1px solid;
  padding: 15px 0;
  border-radius: 10px;
}

.room-warning-icon-wrapper {
  display: flex;
  justify-content: right;
  margin-right: -7%;
}

.room-warning-icon {
  padding: 20px;
  border: 1px solid;
  margin-left: 10px;
  background-color: #1a1a1a;
  margin-top: -130px;
  height: 20px;
}

.room-warning-icon.icon-cold {
  background: #00b7ff;
  border-radius: 10px;
}

.room-warning-icon.icon-humidity {
  background-color: #d300ff;
  border-radius: 10px;
}

.room-numbers {
  display: flex;
  justify-content: space-around;
  font-size: 28px;
  margin-bottom: 5%;
  margin-top: -10%;
}

.room-temperature {
  border-right: 1px solid;
}

.room-numbers div {
  width: 50%;
}
.room-last-update {
  margin-bottom: 5%;
}
</style>
